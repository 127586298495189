<template>
  <v-container fluid>
    <Header @create-custom-field="onCreateCustomField" />

      <Placeholder @action='onCreateCustomField' :btnText="$t('customFieldPage.createCustomField')" :title="$t('customFieldPage.emptyTitle')" v-if='!originalCustomFields.length'/>
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%" v-else>
      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="auto" class="d-flex align-center">
          <SearchComponent
            :search="filter.name"
            @update:search="filter.name = $event"
            :placeholder="$t('placeHolder.searchByName')"
          />

          <FilterDialog
            :data="filter"
            :data-sources="dataSources"
            @update-filter-condition="updateFilterCondition"
          />
        </v-col>

        <v-col cols="12" md="auto">
          <v-row justify="end" class="align-center">
            <SettingsMenu :filterItems="filteredMenuHeaders"/>
          </v-row>
        </v-col>
      </v-row>

      <CustomFieldTable
        :headers="filteredHeaders"
        :items="filteredCustomFields"
        itemKey="uid"
        @edit="onEditCustomField"
        @delete="onDeleteCustomField"
      />
    </v-card>

    <CreateUpdateDialog
      v-model="showCreateUpdateDialog"
      :data="selectedCustomField"
      @create-custom-field="createCustomField"
      @update-custom-field="updateCustomField"
      @close-dialog="showCreateUpdateDialog = false"
    />

    <DeleteConfirmDialog
      v-model="showDeleteConfirmDialog"
      :custom-field="selectedCustomField"
      @delete="deleteCustomField"
    />
  </v-container>
</template>

<script>
import * as _ from 'lodash'
import { createNamespacedHelpers  } from 'vuex';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import makeCustomFieldService from '@/services/api/customField';

import Header from '@/components/Admin/CustomField/Header';
import FilterDialog from '@/components/Admin/CustomField/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import CustomFieldTable from '@/components/Admin/CustomField/CustomFieldTable.vue';
import CreateUpdateDialog from '@/components/Admin/CustomField/CreateUpdateDialog.vue';
import DeleteConfirmDialog from '@/components/Admin/CustomField/DeleteConfirmDialog.vue';
import Placeholder from '@/components/base/Placeholder.vue';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'CustomFields',

  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    CustomFieldTable,
    CreateUpdateDialog,
    DeleteConfirmDialog,
    Placeholder,
  },

  data() {
    return {
      filter: {
        name: '',
        types: [],
        sources: [],
        startDate: null,
        endDate: null,
      },
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
          checked: true
        },
        {
          text: this.$t('customFieldPage.dataType'),
          value: 'type',
          sortable: true,
          checked: true
        },
        {
          text: this.$t('creationDate'),
          value: 'createdAt',
          sortable: true,
          checked: true
        },
        {
          text: this.$t('customFieldPage.dataSource'),
          value: 'source',
          sortable: true,
          checked: true,
        },
        {
          text: 'Actions',
          value: 'uid',
          sortable: false,
          class: 'rounded-r-lg hidden',
          width: 130,
        },
      ],
      originalCustomFields: [],
      filteredCustomFields: [],
      showCreateUpdateDialog: false,
      showDeleteConfirmDialog: false,
      selectedCustomField: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
      customFieldService: null,
      isLoading: false,
      errorMessage: '',
      dataSources: [],
    }
  },

  computed: {
    ...mapState(['currentAccount']),
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    filteredMenuHeaders() {
      return this.headers.filter((header) => header.text !== 'Actions')
    },
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function () {
        this.filterCustomFields()
      }, 500),
    },
  },

  async mounted() {
    this.customFieldService = makeCustomFieldService(this.$api);
    await this.init(this.currentAccount.handle);
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      console.log("not run")
      next();
    }
  },

  methods: {
    async init(handle) {
      await this.getCustomFields(handle);
    },
    async getCustomFields(handle) {
      this.isLoading = true
      try {
        const response = await this.customFieldService.getCustomFields(handle);
        this.originalCustomFields = response.data

        this.dataSources = _.uniq(_.map(this.originalCustomFields, 'source'))
      } catch (err) {
        this.isLoading = true
        this.errorMessage = err.message;
      }

      this.filterCustomFields();
    },

    onCreateCustomField() {
      this.selectedCustomField = {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      }

      this.showCreateUpdateDialog = true
    },

    async createCustomField(customField) {
      this.showCreateUpdateDialog = false;

      try {
        const response = await this.customFieldService.createCustomField(this.currentAccount.handle, {
          ...customField,
          source: 'Manual',
        });

        this.originalCustomFields.push(response.data);
        showSuccessToast(this.$swal, 'createSuccess', { item: 'Custom field' }); // {{ edit_1 }}
      } catch (err) {
        this.isLoading = true;
        this.errorMessage = err.message;
        showErrorToast(this.$swal, 'createError', { item: 'Custom field' }); // {{ edit_2 }}
      }

      this.filterCustomFields();
    },

    onEditCustomField(customField) {
      this.selectedCustomField = {
        uid: customField.uid || '',
        name: customField.name ||  '',
        type: customField.type || '',
        source: customField.source || '',
        options: customField.options || [],
      }

      this.showCreateUpdateDialog = true
    },

    async updateCustomField(customField) {
      this.showCreateUpdateDialog = false;

      try {
        const response = await this.customFieldService.updateCustomField(this.currentAccount.handle, customField);

        this.originalCustomFields = this.originalCustomFields.map(item => {
          if (item.uid !== customField.uid) {
            return item;
          }

          return response.data;
        });
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'Custom field' }); // {{ edit_3 }}
      } catch (err) {
        this.isLoading = true;
        this.errorMessage = err.message;
        showErrorToast(this.$swal, 'updateError', { item: 'Custom field' }); // {{ edit_4 }}
      }

      this.filterCustomFields();
    },

    onDeleteCustomField(customField) {
      this.selectedCustomField = {
        uid: customField.uid || '',
        name: customField.name ||  '',
        type: customField.type || '',
        source: customField.source || '',
        options: customField.options || [],
      }

      this.showDeleteConfirmDialog = true
    },

    async deleteCustomField() {
      this.showDeleteConfirmDialog = false;

      try {
        await this.customFieldService.deleteCustomField(this.currentAccount.handle, this.selectedCustomField.uid);

        this.originalCustomFields = this.originalCustomFields.filter(item => item.uid !== this.selectedCustomField.uid);
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Custom field' }); // {{ edit_5 }}
      } catch (err) {
        this.isLoading = true;
        this.errorMessage = err.message;
        showErrorToast(this.$swal, 'deleteError', { item: 'Custom field' }); // {{ edit_6 }}
      }

      this.filterCustomFields();
    },

    updateFilterCondition(data) {
      this.filter = {
        ...this.filter,
        types: data.types,
        sources: data.sources,
        startDate: data.startDate,
        endDate: data.endDate,
      }

      this.filterCustomFields()
    },

    filterCustomFields() {
      let filteredCustomFields = _.cloneDeep(this.originalCustomFields)

      if (this.filter.name) {
        const searchTerm = this.filter.name.toLowerCase(); // Convert search term to lowercase
        filteredCustomFields = filteredCustomFields.filter(item => item.name.toLowerCase().includes(searchTerm)); // Convert item name to lowercase
      }

      if (this.filter.types.length > 0) {
        filteredCustomFields = filteredCustomFields.filter(item => this.filter.types.includes(item.type))
      }

      if (this.filter.sources.length > 0) {
        filteredCustomFields = filteredCustomFields.filter(item => this.filter.sources.includes(item.source))
      }

      if (this.filter.startDate) {
        filteredCustomFields = filteredCustomFields.filter(item => item.createdAt >= this.filter.startDate)
      }

      if (this.filter.endDate) {
        filteredCustomFields = filteredCustomFields.filter(item => item.createdAt <= this.filter.endDate)
      }

      this.filteredCustomFields = filteredCustomFields
    }
  }
}
</script>