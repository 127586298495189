import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeCustomFieldService(api) {
  return {
    getCustomFields: function (handle) {
      return api.get(`/${handle}/customFields`);
    },

    createCustomField: function (handle, data) {
      return api.post(`/${handle}/customFields`, data);
    },

    updateCustomField: function (handle, { uid, ...data }) {
      return api.patch(`/${handle}/customFields/${uid}`, data);
    },

    deleteCustomField: async function (handle, uid) {
      return api.delete(`/${handle}/customFields/${uid}`);
    },
  }
}
